import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from './toaster.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(err: string) {
    this._snackBar.openFromComponent(ToasterComponent, {
      duration: 5 * 1000,
      data: { err: err },
      verticalPosition: 'top',
    });
  }



}
